/* .btn {

  transition: all 0.4s ease-in;
}
.btn span[class^='ion'] {
  position: relative;
}
.btn:before {
  content: '';
  background-color: aliceblue;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: scale(0.001, 0.001);
          transform: scale(0.001, 0.001);
}
.btn:focus {
  outline: 0;
  color: #fff;
} */
/* .btn:focus:before {
  -webkit-animation: effect_dylan 0.8s ease-out;
          animation: effect_dylan 0.8s ease-out;
} */
@-webkit-keyframes effect_dylan {
  50% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
@keyframes effect_dylan {
  50% {
    -webkit-transform: scale(1.5, 1.5);
            transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.001, 0.001);
            transform: scale(0.001, 0.001);
    opacity: 1;
  }
}



.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


 

@keyframes requestClickAni {
  
  0% {opacity:1;height: 100px;}
  100% {opacity:0;height: 1px;}
  
}

.requestClickAni{
  
  overflow: hidden;
  animation-name: requestClickAni;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  
}
 
@keyframes lblMsgCountAni {
  
  0% {opacity:0;}
  80% {opacity:0;}
  100% {opacity:1;}
  
}

.lblMsgCountAni{
  animation-name: lblMsgCountAni;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  
}


@keyframes fadeOut2 {
  
  0% {opacity:1;}
  100% {opacity:0;}
  
}

@keyframes fadeIn2 {
  
  0% {opacity:0;transform: scale(0.6);}
  100% {opacity:1;transform: scale(1);}
  
}
.xfadeIn2{
  /*  WE only want this on the edit shift*/
  /* animation-iteration-count: infinite; */
  animation-name: fadeIn2;
  animation-duration: .1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  /* transition: all .2s ease-in-out; */
  
}
.fadeOut2{
  
  /* animation-iteration-count: infinite; */
  animation-name: fadeOut2;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
}



@keyframes moveOutLeft {
  
  0% {left:0px;}
  100% {left:-200px;}
  
}

.moveOutLeft{
    -webkit-transform: translate3d(0, 0, 0); 
  transform: translate3d(0, 0, 0); 
  /* animation-iteration-count: infinite; */
  position: relative;
  animation-name: moveOutLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  
}

@keyframes fadeOut {
  
  0% {opacity:1;}
  100% {opacity:0;}
  
}

.fadeOut{
  -webkit-transform: translate3d(0, 0, 0); 
  transform: translate3d(0, 0, 0); 
  /* animation-iteration-count: infinite; */
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  
  0% {opacity:0;}
  100% {opacity:1;}
  
}

.fadeIn{
  /* animation-iteration-count: infinite; */
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  
}
/* .main{animation: fadeIn 1s; } */
.menuLandscape{animation: fadeIn 1s; }
 

@keyframes modalAniSlideUp {
  
  0% { top:50px;opacity:0;}


  100% { top:20px;opacity:1;}

}

 .modalAniSlideUp {
 
  animation-name: modalAniSlideUp;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  
}

@keyframes modalAniSlideLeft {
  
  0% { left:-500px;opacity:0;}


  100% { left:0px;opacity:1;}

}




.modalAniTimepickerSlideUp {
  -webkit-transform: translate3d(0, 0, 0); 
  transform: translate3d(0, 0, 0);  
  animation-name: modalAniTimepickerSlideUp;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  
}

@keyframes modalAniTimepickerSlideUp {
  
  0% { bottom: -200px;opacity: 1;}


  100% { bottom: 0px; opacity: 1;}

}



 .modalAniSlideLeft {
  -webkit-transform: translate3d(0, 0, 0); 
  transform: translate3d(0, 0, 0); 
  position :absolute;
  animation-name: modalAniSlideLeft;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  
}

@keyframes modalAni {
  
  0% {transform: scale(0.9);}


  100% {transform: scale(1);}

}

 .modalAni {
  
  position :relative;
  animation-name: modalAni;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  
}




@keyframes slideAniRight {
  
  0% {left: -500px;}
  100% {left: 0px}

}

 .slideAniRight {
  
  position :relative;
  animation-name: slideAniRight;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  
}


@keyframes slideAniLeft {
  
  0% {right: -500px;}
  100% {right: 0px}

}

 .slideAniLeft {
  
  position :relative;
  animation-name: slideAniLeft;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  
}




@keyframes menuAnimation {
  
  0% {transform: scale(0);}
  100% {transform: scale(1);}

}

 .menuAnimation {
  
  position :relative;
  animation-name: menuAnimation;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  
}




@keyframes login-HideElements {
  
  0% {opacity: 1;}
  100% {opacity: 0;}

}

 .loginHidebox .loginHideElements {
  
  position :relative;
  animation-name: login-HideElements;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  
}


 


@keyframes login-hidebox {
  0% {top:0px;left:0px;}
  100% {width:100%;}
}

.loginHidebox {

  position :relative;
  animation-name: login-hidebox;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}

@keyframes login-hidebox-logoAni {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.loginHidebox .logoAni {
  position :relative; 
  animation-name: login-hidebox-logoAni;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

@keyframes login-hidebox-img {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.loginHidebox img {
  animation-name: login-hidebox-img;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes login-hidebox-appTitle {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.loginHidebox .appTitle {
  
  animation-name: login-hidebox-appTitle;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}



