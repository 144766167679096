 .checkbox  label {
  color:var(--color-font)!important;
}
.ui.basic.table tbody tr {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}

.ui.secondary.pointing.menu {
  -webkit-app-region: drag;
}
.ui.input > input{
  padding:5px!important;
}

.ui.inverted.blue.button:hover{
  color:#fff!important;
}
.ui.inverted.blue.button{
  color:darkgray!important;
}
.ui.positive.button{
  background-color: var(--color-btn-green);
}
.ui.positive.button:hover{
  background-color: var(--color-btn-green-h);
} 

.ui.positive.button:active{
  background-color: var(--color-btn-green-a);
} 

.ui.red.button{
  background-color: var(--color-btn-red);
}
.ui.red.button:hover{
  background-color: var(--color-btn-red-h);
} 

.ui.red.button:active{
  background-color: var(--color-btn-red-a);
} 

.ui.blue.button{
  background-color: var(--color-btn-blue);
}
.ui.blue.button:hover{
  background-color: var(--color-btn-blue-h);
} 

.ui.blue.button:active{
  background-color: var(--color-btn-blue-a);
} 

.ui.button{
  background-color: var(--color-btn-gray);
}
.ui.button:hover{
  background-color: var(--color-btn-gray-h);
} 

.ui.button:active{
  background-color: var(--color-btn-gray-a);
} 

  

.ui.secondary.pointing.menu .active.item
{
  border-color: rgba(34, 36, 38, 1.15)!important;
  color: var( --color-menu-selected)!important;
}

.ui.secondary.pointing.menu .item
{
  border-color: rgba(34, 36, 38, 0.15)!important;
  color: var( --color-menu-noselected)!important;
}
.ui.secondary.pointing.menu{
  border-color: rgba(34, 36, 38, 0.15)!important;
}

.ui.modal > .header{
  color:var(--color-font);
  background-color: var(--color-bg1)!important
}
.ui.modal > .actions{
  background-color: var(--color-bg1)!important
}
.ui.modal > .content{
  background-color: var(--color-bg1)!important
}
.ui.modal{
  background-color: var(--color-bg1)!important
}
.ui.menu{
background-color: var(--color-bg1)!important
}

.ui.top.left.pointing.dropdown > .menu{
  background: var(--color-bg-dropdown)!important;
}
.sMenu_s1 > .ui.dropdown .menu{
  background-color: transparent!important;
}
.ui.floating.dropdown .menu{
  background-color: transparent!important;
}

.dropdown.item .menu {
  border-radius: 3px!important;
  background: var(--color-bg-dropdown)!important;
}
.ui.attached.menu:not(.tabular) {
  /* background:hsl(0,0%,96%)!important; */
}
.ui.menu:not(.vertical) .item > .button
{
  padding: 0.58928571em 1.125em 0.58928571em!important;
}
 
.ui.labeled.icon.menu {
    background: var(--color-bg2)!important;
}
.ui.icon.menu .item
{
  background:var(--color-bg2)!important;
}

:not(body.moblie) body.animating.in.dimmable, body.dimmed.dimmable {
   overflow-y: auto!important; 
   background-color: #fff!important;
}
:not(body.moblie) .scrolling.dimmable.dimmed>.dimmer {
  overflow:hidden!important;
  /* -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0); */
}

.ui.inverted.dimmer {
  background-color: rgba(255,255,255,0.5)!important;
}
.ui.pointing.dropdown > .menu:after{
  background-color:var(--color-bg-dropdown)!important; 
}

.ui.vertical.pointing.menu .active.item:after {
  background-color:var(--color-bg2)!important;
  border-color:var(--color-bg1)!important;
}
.ui.pointing.menu .active.item:after {
  background-color:var(--color-bg2)!important;
  border-color:var(--pointer)!important;
}

.ui.floating.dropdown .menu {
  /* left: 0; */
  /* right: auto; */
   box-shadow: none!important; 
   border-radius: none!important; 
}


:not(i) 
{
  xfont-family:system, -apple-system, '.SFNSText-Regular', 'SF UI Text', 'Lucida Grande', 'Segoe UI', Ubuntu, Cantarell, sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */

  /* font-family: 'Nunito', sans-serif!important; */
  /* -webkit-font-smoothing: antialiased; */
}

.ui
{
  xfont-family:system, -apple-system, '.SFNSText-Regular', 'SF UI Text', 'Lucida Grande', 'Segoe UI', Ubuntu, Cantarell, sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */

  /* font-family: 'Nunito', sans-serif!important; */
  /* -webkit-font-smoothing: antialiased; */
}


.modal{
  /* border-radius: 20px!important; */
  /* box-shadow:none!important; */
  
}
.modal .header{
  /* border-top-right-radius: 20px!important;
  border-top-left-radius: 20px!important; */
}.modal .actions{
  /* border-bottom-right-radius: 20px!important;
  border-bottom-left-radius: 20px!important;
   */
}
.dropdown, .card{
  border-radius: 10px!important;
}
input,button{
  /* border-radius: 10px!important; */
}


.ui.table tr td{ 
  border-top:none!important;
  padding:2px!important;
}

.topMenuItem{background-color: transparent!important;}
.topMenuItem i:hover{
  color:var(--color-menu-selected);
}




.sMenu_s1 .ui.menu .active.item{
  background-color: transparent!important
}
.ui.menu .active.item>i.icon {
  color:var(--color-menu-selected)!important;
}
.menuLandscape .ui.menu .item>i.icon {
  color:var(--color-menu-noselected);
}
.ui.menu .item>i.icon {
  color:var(--color-menu-icon);
}

.menuPortrait .ui.menu .active.item  {
  background-color:var(--color-bg2)!important;
}
 .ui.menu .item:before{
  background: none!important;
}

.menuPortrait .ui.menu {
  border: none!important; 
  box-shadow: none!important; 
}



.ui.attached.menu:not(.tabular) {
  /* top menu border */
  border-bottom:solid 0px var(--color-border);
  border-top:none;
  border-left:none;
  border-right:none;
 
}
.ui.menu .item:before {
  background: rgba(34,36,38,0);
}
  
.userCard a{
  color: rgba(0,0,0,.87);
  text-decoration: none;
  font-size: 12px;
}

.ui.labeled.icon.menu .item{
  min-width: 10px!important;
}


.ui.error.message p {
  color: #9F3A38!important;
}