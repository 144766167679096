@media print {
    
    html,body,table{
        margin:0px;
        padding:0;
    }
    @page  
    { 
        
        /* this affects the margin in the printer settings */ 
        margin-bottom: 10mm; 
        margin-left: 10mm; 
        margin-right: 10mm;  
        margin-top: 10mm; 
       
         
    }
    
    

    table { page-break-after:auto;position: absolute;top:0px;border:none!important;}
    tr    { page-break-inside:avoid; page-break-after:auto ;border:none!important;}
    td    { page-break-inside:avoid; page-break-after:auto ;}
    thead {
        display:table-row-group;
    }  
    /* tr:first-child {
        background: yellow!important;
        padding-top:40px!important;
        height: 300px;
    } */

    .tblSchedualHead{
        position: relative!important;
        xdisplay:table-header-group
    }
    .menuLandscape {
        display:none!important;
      }
    body.landscape div.main {
        padding-left: 0px!important;
    
    }
    .noPrint{display:none!important;}

    /* @page :not(:first) {
        margin-top:250px;
    } */
}
