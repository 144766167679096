 
.xred {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(219, 40, 40, 0.3)!important;background-color: var(--color-xred)!important;}
.xorange {border:solid 0px #f1f1f1!important; border-bottom:solid 4px rgba(242, 113, 28, 0.3)!important;background-color: var(--color-xorange)!important;}
.xyellow {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(251, 189, 8, 0.3)!important;background-color: var(--color-xyellow)!important;}
.xolive   {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(181, 204, 24, 0.3)!important;background-color: var(--color-xolive)!important;}
.xgreen   {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(33, 186, 69, 0.3)!important;background-color: var(--color-xgreen)!important;}
.xteal     {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(2, 195, 154, 0.3)!important;background-color: var(--color-xteal)!important;}
.xblue  {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(33, 133, 208, 0.3)!important;background-color: var(--color-xblue)!important;}
.xviolet {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(100, 53, 201, 0.3)!important;background-color: var(--color-xviolet)!important;}
.xpurple {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(163, 51, 200, 0.3)!important;background-color: var(--color-xpurple)!important;}
.xpink   {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(224, 57, 151, 0.3)!important;background-color: var(--color-xpink)!important;}
.xbrown   {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(165, 103, 63, 0.3)!important;background-color: var(--color-xbrown)!important;}
.xgrey   {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(118, 118, 118, 0.3)!important;background-color: var(--color-xgrey)!important;}
.xblack {border:solid 0px #f1f1f1!important;border-bottom:solid 4px rgba(27, 28, 29, 0.3)!important;background-color: var(--color-xblack)!important;}

.yred {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(219, 40, 40, 0.8)!important;background-color: var(--color-xred)!important;}
.yorange {border:solid 0px #f1f1f1!important; border-left:solid 10px rgba(242, 113, 28, 0.8)!important;background-color:var(--color-xorange)!important;}
.yyellow {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(251, 189, 8, 0.8)!important;background-color: var(--color-xyellow)!important;}
.yolive   {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(181, 204, 24, 0.8)!important;background-color: var(--color-xolive)!important;}
.ygreen   {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(33, 186, 69, 0.8)!important;background-color: var(--color-xgreen)!important;}
.yteal     {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(2, 195, 154, 0.8)!important;background-color: var(--color-xteal)!important;}
.yblue  {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(33, 133, 208, 0.8)!important;background-color: var(--color-xblue)!important;}
.yviolet {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(100, 53, 201, 0.8)!important;background-color:var(--color-xviolet)!important;}
.ypurple {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(163, 51, 200, 0.8)!important;background-color: var(--color-xpurple)!important;}
.ypink   {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(224, 57, 151, 0.8)!important;background-color: var(--color-xpink)!important;}
.ybrown   {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(165, 103, 63, 0.8)!important;background-color: var(--color-xbrown)!important;}
.ygrey   {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(118, 118, 118, 0.8)!important;background-color:var(--color-xgrey)!important;}
.yblack {border:solid 0px #f1f1f1!important;border-left:solid 10px rgba(27, 28, 29, 0.8)!important;background-color:  var(--color-xblack)!important;}
.ysick {border:solid 0px #f1f1f1!important;border-left:solid 0px var(--color-xblack)!important;background-color:  var(--color-xblack)!important;padding-left: 18px!important}



.rxred {border:solid 0px #f1f1f1!important;border-bottom:solid 4px #db2828!important;background-color: rgba(219, 40, 40, 0.1)!important;}
.rxorange {border:solid 0px #f1f1f1!important; border-bottom:solid 4px #f2711c!important;background-color: rgba(242, 113, 28, 0.1)!important;}
.rxyellow {border:solid 0px #f1f1f1!important;border-bottom:solid 4px #fbbd08!important;background-color: rgba(251, 189, 8, 0.1)!important;}
.rxolive   {border:solid 0px #f1f1f1!important;border-bottom:solid 4px #b5cc18!important;background-color: rgba(181, 204, 24, 0.1)!important;}
.rxgreen   {border:solid 0px #f1f1f1!important;border-bottom:solid 4px #21ba45!important;background-color: rgba(33, 186, 69, 0.1)!important;}
.rxteal     {border:solid 0px #f1f1f1!important;border-bottom:solid 4px #02c39a!important;background-color: rgba(2, 195, 154, 0.1)!important;}
.rxblue  {border:solid 0px #f1f1f1!important;border-bottom:solid 4px #2185d0!important;background-color: rgba(33, 133, 208, 0.1)!important;}
.rxviolet {border:solid 1px #f1f1f1!important;border-bottom:solid 4px #6435c9!important;background-color: rgba(100, 53, 201, 0.1)!important;}
.rxpurple {border:solid 1px #f1f1f1!important;border-bottom:solid 4px #a333c8!important;background-color: rgba(163, 51, 200, 0.1)!important;}
.rxpink   {border:solid 1px #f1f1f1!important;border-bottom:solid 4px #e03997!important;background-color: rgba(224, 57, 151, 0.1)!important;}
.rxbrown   {border:solid 1px #f1f1f1!important;border-bottom:solid 4px #a5673f!important;background-color: rgba(165, 103, 63, 0.1)!important;}
.rxgrey   {border:solid 1px #f1f1f1!important;border-bottom:solid 4px #767676!important;background-color: rgba(118, 118, 118, 0.1)!important;}
.rxblack {border:solid 1px #f1f1f1!important;border-bottom:solid 4px #1b1c1d!important;background-color: rgba(27, 28, 29, 0.1)!important;}
 