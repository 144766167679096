.divLoginBox *{color:#ccc; }
.divLoginBox .btn{color:#888; }
/*small screen*/
.loginBox  h2{  margin-bottom:35px;}
.loginBox2  h2{  margin-bottom:20px;}

@media only screen and (max-width:770px) {
     
  .loginBox h2{margin-bottom:4px}
  .loginBox2 h2{margin-bottom:4px}

  .loginErrorDialog {position: absolute;top:-64px ;width: 100% }
  .bgCover{
    background-image: none!important;
    position: relative!important; 
  }
  .loginBorderRight{
     border-right: none!important;
     border-bottom: dotted 1px #585858;
     padding-right:0px; 
     padding-bottom:20px; 
     margin-bottom:20px; 
  }

  .divLoginBox{
    height: 100%;
    width: 100%;
    cbackground-color:var(--color-bg1);
     xbackground-color: rgb(69, 69, 68);
     background: linear-gradient(to right, #936b78 0%, #6a6f91 100%);
    padding-left:30px;
    padding-top: 20px;
    padding-right:30px;

  }

  .loginBox{
    
     float: none; 
  }
  .loginBox div{
    width: 100%!important
  }
  .loginBox2{
    
     float: none; 


  }
}



/* big screen */
@media only screen and (min-width:770px) { 
  .loginErrorDialog {padding-top: 65px}
  .bgCover{
    background-size: cover; 
    xbackground-position: -151px -144px; 
    height: 100%; 
    position: fixed; 
    width: 100%; 
    left: 0px; 
    top: 0px;
    overflow: auto;
  }
  .divLoginBox{
    margin-left: auto;
    height: 100%;
    width:600px;
    xbackground-color:var(--color-bg1);
    xbackground-color: rgb(69, 69, 68);
    background: linear-gradient(to right, #936b78 0%, #6a6f91 100%);
    padding-left:50px;
    padding-top: 20px;
    padding-right:50px;
  }

  .loginBox{
    width:50%;
     float: left; 
  }
  .loginBox2{
    width:50%;
    float: left;  
     padding-left: 25px;
  }
}