.tHeadPrint{
  /* padding-top: 40px; */
} 
.tblPrintTable{
 page-break-inside:avoid !important; 
 page-break-after:always !important;
}

@media screen {
  div.divFooter {
    display: none;
  }
}

@media print {
  div.divFooter {
    position: fixed;
    bottom: 0;
    right: 10px;
    color:silver;
  }
 
    
  @page {  size: landscape; margin: 0;  }
  body { 
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 1cm;
    margin-right: 1cm; 
  } 

  html, body { height: auto; }
  /* .schedulePrint{ margin: 40px } */
  .printHidden{display: none}
  .printShow{display: block}
  .scheduelCell{ 
    /* border:solid 1px red!important; */
    /* border-width:0px 1px 1px 0px !important; */
 }

 .tblBreakerNoHeader{
   margin-top:50px!important
 }
  
  table {
    display: block;
    page-break-inside:auto; 
    border:solid 1px #f1f1f1!important;
    border-width:1px 1px 1px 1px !important;
    padding-left:10px;
    padding-right:10px;
    
  }
  tr { 
     page-break-inside:avoid !important; 
     page-break-after: auto!important;
     border:solid 0px green !important;
     border-width:0px 0px 0px 0px !important;
  } 


  th, td {
    
    border:solid 1px #f1f1f1!important;
    border-width:0px 1px 1px 0px !important;
    
  }
  th, td:last-child
  {
    border:solid 1px #f1f1f1!important;
    border-width:0px 0px 1px 0px !important;
  }
  tr:last-child td
  {
    border:solid 1px #f1f1f1!important;
    border-width:0px 1px 0px 0px !important;
    
  }
  tr:last-child 
  {
    margin-bottom: 10px;
  }
  tr:last-child td:last-child
  {
    border:solid 1px #f1f1f1!important;
    border-width:0px 0px 0px 0px !important;
  }  
  .scheduleDivider{
    border:solid #888 !important;
    border-width:0px 0px 1px 0px !important;
    /* box-shadow: inset 0 0 0 1000px #f1f1f1; */
    /* page-break-after: always!important; */

     
  }
 }