

/* @media print{ body{ -webkit-print-color-adjust: exact; } } */

.scheduleDivider h4{font-weight:bold!important;}
.scheduleDivider{
  position: relative;
  
  /* background-color: rgba(192,192,192,0.1)!important;  */
  border-bottom: solid 0px #f1f1f1!important;
  border-top: solid 0px #f1f1f1!important;
  
  height:10px!important;
  overflow:hidden!important;
  padding:0px!important;
  padding-top:20px!important;
  
}



 

 .scheduelCell{ 
    height: 30px;
    border-left:dotted 1px var(--color-border);
    padding:4px!important; 
    position:relative
 }
 .scheduelCell > .addShiftBtn{
  position:absolute;
  top:2px;
  right:2px;
  /* z-index:99; */
  
 }
 .scheduelCell > .flexDiv{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
 }


 
.schedualUsername img{ 
  float: left; 
  margin-right: 8px;
  margin-bottom: 0px;
  margin-top: 0px;
  cursor: grab;

  
 }
 .schedualUsername div{ 
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
   padding-top: 10px; 
 }


 .cellUsername{
  background-color: var(--color-bg1)!important;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  padding:0px!important;
  position: relative;
} 
.cellUsername > img{
 margin:4px; 
 
}


/*small screen*/
@media only screen and (max-width:770px) {
  .schedualUsername img{ float: none;}
  .schedualUsername div{
    padding-top: 0px;
    float:left;
    font-size: 11px!important;
    font-weight: bold;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis}
 
 
  .scheduelHeaderCell{ 
    font-size:13px; 
    
  }

  .cellUsername > img{
    /* margin:0px; 
    padding:0px; 
    margin-top:2px; 
    width: 42px!important ; */

  }


  .cellMulti{
    flex-grow:1;
    padding:6px!important; 
    margin:1px!important;
    /* width:calc(100% -2px) */
  }

  .cellMulti > div{
    font-size: 10px!important;
  }
  .cellMulti > span{
    font-size: 8px!important;
    font-weight: 400;
  }

/* 
  .cellMultiUserhome > div{
    font-size: 20px!important;
  }
  .cellMultiUserhome > span{
    font-size: 18px!important;
    font-weight: 400;
  } */



 
}