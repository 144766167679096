/* :root {
  --mainColor: deepskyblue;
  --bottom-menu-height:50px;
} */



:root {

  --color-btn-red:#DB2828;
  --color-btn-red-a:#b21e1e;
  --color-btn-red-h:#d01919;
  --color-btn-green:#21BA45;
  --color-btn-green-a:#198f35;
  --color-btn-green-h:#16ab39;
  --color-btn-blue:#2185D0;
  --color-btn-blue-h:#1678c2;
  --color-btn-blue-a:#1a69a4;
  --color-btn-gray:#E0E1E2;
  --color-btn-gray-h:#CACBCD;
  --color-btn-gray-a:#BABBBC;
  
  --color-xred:rgba(219, 40, 40, 0.1);
  --color-xorange:rgba(242, 113, 28, 0.1);
  --color-xyellow: rgba(251, 189, 8, 0.1);
  --color-xolive: rgba(181, 204, 24, 0.1);
  --color-xgreen: rgba(33, 186, 69, 0.1);
  --color-xteal : rgba(2, 195, 154, 0.1);
  --color-xblue : rgba(33, 133, 208, 0.1);
  --color-xviolet: rgba(100, 53, 201, 0.1);
  --color-xpurple: rgba(163, 51, 200, 0.1);
  --color-xpink : rgba(224, 57, 151, 0.1);
  --color-xbrown: rgba(165, 103, 63, 0.1);
  --color-xgrey : rgba(118, 118, 118, 0.1);
  --color-xblack: rgba(27, 28, 29, 0.1);

  --color-bg1:#fff;
  --color-bg2: hsl(0,0%,96%);
  --color-menu-selected:deepskyblue;
  --color-menu-noselected:rgb(56, 56, 56);
  --color-font:rgba(0, 0, 0, 0.87);
  --cell-color-text:#434242;
  --color-menu-icon:rgba(0, 0, 0, 0.87);
  --color-text-header:gray;
  --color-bg-dropdown:#f5f5f5;
  --menuLandscapeborder:  rgba(34, 36, 38, 0.15);
  --inputbg:#fff;
  --pointer:rgba(34,36,38,0.1);
  --color-border:rgba(34, 36, 38, 0.15);
  --color-border2:rgba(34, 36, 38, 0.05);
  --color-border-menu:rgba(34, 36, 38, 0.15);
}

[data-theme^="light"],[data-theme] [data-theme^="light"] {
   
  --color-btn-red:#DB2828;
  --color-btn-red-a:#b21e1e;
  --color-btn-red-h:#d01919;
  --color-btn-green:#21BA45;
  --color-btn-green-a:#198f35;
  --color-btn-green-h:#16ab39;
  --color-btn-blue:#2185D0;
  --color-btn-blue-h:#1678c2;
  --color-btn-blue-a:#1a69a4;
  --color-btn-gray:#E0E1E2;
  --color-btn-gray-h:#CACBCD;
  --color-btn-gray-a:#BABBBC;

  --color-xred:rgba(219, 40, 40, 0.4);
  --color-xorange:rgba(242, 113, 28, 0.4);
  --color-xyellow: rgba(251, 189, 8, 0.4);
  --color-xolive: rgba(181, 204, 24, 0.4);
  --color-xgreen: rgba(33, 186, 69, 0.4);
  --color-xteal : rgba(2, 195, 154, 0.4);
  --color-xblue : rgba(33, 133, 208, 0.4);
  --color-xviolet: rgba(100, 53, 201, 0.4);
  --color-xpurple: rgba(163, 51, 200, 0.4);
  --color-xpink : rgba(224, 57, 151, 0.4);
  --color-xbrown: rgba(165, 103, 63, 0.4);
  --color-xgrey : rgba(118, 118, 118, 0.4);
  --color-xblack: rgba(27, 28, 29, 0.4);
  
  --color-bg1: #fff;
  --color-bg2: rgba(240, 240, 240, 1);
  --color-menu-noselected:rgba(56, 56, 56,0.8);
  --color-menu-selected:black;
  --color-font:rgba(0, 0, 0, 0.87);
  --cell-color-text:#434242;
  --color-menu-icon:rgba(0, 0, 0, 0.87);
  --color-text-header:gray;
  --color-bg-dropdown:#f5f5f5;
  --menuLandscapeborder:  rgba(34, 36, 38, 0.15);
  --inputbg:#fff;
  --pointer:rgba(34,36,38,0.1);
  --color-border:rgba(34, 36, 38, 0.15);
  --color-border2:rgba(34, 36, 38, 0.05);
  --color-border-menu:rgba(34, 36, 38, 0.15);

  
}

[data-theme^="dark"],[data-theme] [data-theme^="dark"]
{
  --color-btn-red:#a42d24;
  --color-btn-red-a:#b21e1e;
  --color-btn-red-h:#d01919;
  --color-btn-green:#3e960e;
  --color-btn-green-a:#198f35;
  --color-btn-green-h:#16ab39;
  --color-btn-blue:#1a67a1;
  --color-btn-blue-h:#1678c2;
  --color-btn-blue-a:#1a69a4;
  --color-btn-gray:#d2d2d2;
  --color-btn-gray-h:#CACBCD;
  --color-btn-gray-a:#BABBBC;

  --color-xred:rgba(219, 40, 40, 0.4);
  --color-xorange:rgba(242, 113, 28, 0.4);
  --color-xyellow: rgba(251, 189, 8, 0.4);
  --color-xolive: rgba(181, 204, 24, 0.4);
  --color-xgreen: rgba(33, 186, 69, 0.4);
  --color-xteal : rgba(2, 195, 154, 0.4);
  --color-xblue : rgba(33, 133, 208, 0.4);
  --color-xviolet: rgba(100, 53, 201, 0.4);
  --color-xpurple: rgba(163, 51, 200, 0.4);
  --color-xpink : rgba(224, 57, 151, 0.4);
  --color-xbrown: rgba(165, 103, 63, 0.4);
  --color-xgrey : rgba(118, 118, 118, 0.4);
  --color-xblack: rgba(27, 28, 29, 0.4);
  
  --color-bg1: #585858;
  --color-bg2: #22211f;
  --color-menu-selected:#fff;
  --color-menu-noselected:darkgray;  
  --color-font:#dddcdc;
  --cell-color-text:#dddcdc;
  --color-menu-icon:#fff;
  --color-text-header:#fff;
  --color-bg-dropdown:gray;
  --menuLandscapeborder:  var(--color-bg1);
  --inputbg:gray;
  --pointer:var(--color-bg1);
  --color-border:rgba(34, 36, 38, 0.25);
  --color-border2:rgba(34, 36, 38, 0.20);
  --color-border-menu:rgba(34, 36, 38, 0.15);

  
}

.btnLoginbig  {
  font-size: 18px!important;
  height: 50px!important;
}

.btnLoginbig i {
  font-size: 18px!important;
}

.tblReport tr:hover td:first-child i{
  display: inline;
  padding-left: 5px;
}

 .tblReport tr td:first-child i{
  display: none;
  padding-left: 5px;
}

.tblReport tr:hover{
  border-bottom:solid 1px rgba(0, 0, 0, 0.15);
}
.tblReport tr{
  border-bottom:solid 1px rgba(0, 0, 0, 0.05);
  margin:5px;
  height: 30px;
}

[tooltip_t]:before {
  /* needed - do not touch */
  content: attr(tooltip_t);
  position: fixed;
  opacity: 0;
  
  /* customizable */
  transition: all 0.1s ;
  padding: 10px;
background-color: black;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px;
 margin-top:-35px;
font-size:12px;
z-index: 184515452;
}

[tooltip_t]:hover:before {
  /* needed - do not touch */
  opacity: 1;

}
[tooltip_t]:not([tooltip-persistent]):before {
  pointer-events: none;
}



[tooltip]:before {
  /* needed - do not touch */
  content: attr(tooltip);
  position: fixed;
  opacity: 0;
  
  /* customizable */
  transition: all 0.1s ;
  padding: 10px;
background-color: black;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px;
 margin-top:30px;
font-size:12px;
z-index: 184515452;
}

[tooltip]:hover:before {
  /* needed - do not touch */
  opacity: 1;

}
[tooltip]:not([tooltip-persistent]):before {
  pointer-events: none;
}



[tooltip_r]:before {
  /* needed - do not touch */
  content: attr(tooltip_r);
  position: fixed;
  right: 0px;
  opacity: 0;
 
  /* customizable */
  transition: all 0.1s ;
  padding: 10px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  margin-top:30px;
  margin-right:20px;
  
  font-size:12px;
  z-index: 1845152;
}

[tooltip_r]:hover:before {
  /* needed - do not touch */
  opacity: 1;

}
[tooltip_r]:not([tooltip-persistent]):before {
  pointer-events: none;
}



.selectEventForm:focus, input:focus{
  outline: none;
}
.selectEventForm optgroup { font-size:14px; }

.selectEventForm {
  /* text-align-last: right; */
  /* display: block;
  height: auto; */
  /* width: 100%; */
  color: rgba(0, 0, 0, 0.87); 
   border-top: none ;
  border-left: none ;
  border-right: none ;
  border-bottom: none;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease; 
  font-size: 14px;
  font-weight: bold;
  background: var(--color-bg1);

   -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ''; 
}



@media print {
  body,html{
    overflow: scroll!important; 
    height: 100%!important;
  }    

  tbody{height: 100%!important;overflow:unset!important;}

.addShiftBtn{display:none;}
}

/* IOS  */
body,html{
  overflow: hidden!important; 
}

.x{
  /* SICK  */
  opacity: 0.5;
  
}
.x div, .x span,.x i{color:#000!important;}
 
*{
  font-family: 'Roboto', 'BlinkMacSystemFont', system, -apple-system,  '.SFNSText-Regular', 'SF UI Text', 'Lucida Grande', 'Segoe UI', Ubuntu, Cantarell, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:12px;
} 
body,td,span,p,div,label,a,th,h1,h2,h3,h4,h5{color:var(--color-font)}

input[type="text"],input[type="mail"],input[type="tel"],input[type="password"] {
  background-color: var(--inputbg)!important;
  color:var(--color-font)!important;
  /* x-webkit-user-select:auto!important; */
}

.break-long-words {
  white-space: normal!important;
  display: inline-block;
    word-wrap: break-word;
    word-break: break-all;
}

textarea {
  background-color: var(--inputbg)!important;
  color:var(--color-font)!important;
}
.txt{
  color:var(--color-font)!important;
}
.topPointer {
  position: absolute;
  content: '';
  top: 9px;
  right: 16px;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  background: #f5f4f5;
  margin: 0.5px 0em 0em;
  width: 9px;
  height: 9px;
  border: none;
  border-left: 1px solid #c2c0c2;
  border-top: 1px solid #c2c0c2;
  z-index: 2;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
}

.selectUserBtn:hover{
  background-color: var(--color-xgreen)!important;
} 

.onDrag {
  -webkit-user-drag: none!important;
	-webkit-app-region: no-drag!important;
}

button {
    -webkit-app-region: no-drag;
}

 .mainToday {
  xmargin-top:10px;
  background-color: transparent!important;
  xpadding-left: 0px;
  xpadding-top: 8px;
  xpadding-bottom: 8px;
  xborder-top-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
   
  xborder: 1px solid var(--color-border);
  overflow: hidden;
  
 }

 .macHeader{
  width:100%;
  font-weight: bold;
  padding:3px;
  background-color: var(--color-bg2);
  xbackground-image: linear-gradient(to bottom,#e8e6e8 0,#d1cfd1 100%);
  text-align: center; 
  font-size: 13px;
  line-height: 1.6;
  color: var(--color-font);
  padding-right: 10px;
  padding-bottom:2px;
  xborder-bottom: 1px solid var(--color-border);
  xbox-shadow: inset 0 1px 0 #f5f4f5;
 }


 .macFooter{
  width:500px;
  xborder:solid 1px red;
   min-height: 20px;
  padding:3px;
  xborder-top: 1px solid rgba(34, 36, 38, 0.15);;
  xbox-shadow: inset 0 1px 0 #f5f4f5;
 background-color:  var(--color-bg1);
 xbackground-image: linear-gradient(to bottom,#e8e6e8 0,#d1cfd1 100%);
}

 .mainTodayScroll{
  xoverflow-y: auto; 
  xoverflow-x: hidden;
  max-height: 590px;
  xpadding:1px;
  background-color:var(--color-bg1);
  padding-left: 6px;
  padding-bottom: 6px;
  border:solid 0px red;
  width:100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
 }


.dragWindow{
  -webkit-app-region: drag;
   width: 100%;
   
}
html{
  /* overflow-y: auto; */
  overflow: hidden;
  height:100%; 
  background-color: transparent!important; 

}
html, body {
  /* -webkit-tap-highlight-color: transparent;    */
  /* touch-action:   none;   */
  /* touch-action: none; */
  height:100%; 
  padding-left:0px;
  -webkit-user-select:none;


 }
 div,span,td,th{
  font-size: 14px;
 }
 /* * {
  touch-action: none;
}  */
body{
  -webkit-overflow-scrolling: touch;  
  overscroll-behavior-y: none;
  
   background-color:   var(--color-bg1);  

   overflow-y: auto;
   
   margin-top: constant(safe-area-inset-top);
   margin-top: env(safe-area-inset-top);
   margin-bottom: constant(safe-area-inset-bottom);
   margin-bottom: env(safe-area-inset-bottom);
}

.electronModalSettings{
  background:var(--color-bg1);
  border-radius:4px;
   z-index: 2;
}
.electronModal{
    xbackground:var(--color-bg1);
    margin-left:10px;
    margin-right:10px;
     border-radius:4px;
    margin-top: 10px;
    z-index: 2;
}
.electronModalSelectuser{
  
  margin-left:10px;

   padding-top:7px;
  padding-bottom:7px;
  border-radius:4px;
  z-index: 2;
}

.electronModalSendinvites{
  
  margin-left:10px;

   padding-top:7px;
  padding-bottom:7px;
  border-radius:4px;
  z-index: 2;
}


.electronModalEvent input,select{
  background:transparent!important;
}
 

.electronModalEvent{
  background:#EFEFEF;
  
  
    xwidth:100%;
    xheight:100%;
    xz-index:1000;
    /* position:fixed; */
    /* bottom:0px;
    right:0pxx;
    left:0px;
    top:0px; */
    xbox-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.1), 1px 3px 10px 2px rgba(0, 0, 0, 0.1);
    xbox-shadow: 0 20px 70px rgba(0, 0, 0, 0.55);
    
        padding:10px;
    border-radius:4px;
    xmargin-top: 10px;
    z-index: 2;
    border: solid 1px rgba(0, 0, 0, 0.1);


}
.pointerDivLeft{
  width: 10px;
  background:#EFEFEF;
  height: 10px;
  position: fixed;
  top: 50%;
  left: 5px;
  z-index: 1;
  transform: rotate(135deg);
  /* box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px 0px, rgba(0, 0, 0, 0.2) 1px 3px 15px 2px; */
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  border-right: solid 1px rgba(0, 0, 0, 0.1);

}
.pointerDivRight{
  width: 10px;
  background:#EFEFEF;
  height: 10px;
  position: fixed;
  top: 50%;
  right: 5px;
  z-index: 12222;
  transform: rotate(135deg);
  /* box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2); */
  border-left: solid 1px rgba(0, 0, 0, 0.1);
  border-top: solid 1px rgba(0, 0, 0, 0.1);

}
 

.fieldInput input {
  border:none!important;
}

.mobileModal{
    background:var(--color-bg1);
    width:100%;
    height:100%;
    z-index:1000;
    position:fixed;
    bottom:0px;
    right:0pxx;
    left:0px;
    top:0px;
    /* padding-left:15px; */
    /* padding-right:15px; */
    /* padding-top:20px; */
    /* padding-bottom:20px; */
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
}
.mobileModalHeader{
  border-bottom: solid 0px gray; 
  position: fixed; 
  left: 0px; 
  top: 0px; 
  width: 100%; 
  background-color: var(--color-bg1); 
  padding: 3px;
  display: flex;
  height:45px;
  margin-top: constant(safe-area-inset-top);
   margin-top: env(safe-area-inset-top);
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
}

.fixedTabs{height:100%}
div.fixedTabs>.menu{
   position: fixed;
  width: 100%;
  background: var(--color-bg1)!important;
  z-index: 3;
  height:47px;
}
 
div.fixedTabs>div:nth-child(2) {
  padding-top:45px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeinLoader {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root{
   /* animation: fadein 3.5s; */
  /* height: calc(100vh); */
}


body div.main{
  /* left:0px; */
  background-color: var(--color-bg1);
  padding-left:0px!important
}
body.landscape div.main{
  /* MAC OS
  padding-left:78px!important;  */
  
}
.dragable{
  -webkit-user-drag:element
}
body.mobile{
   /* padding-bottom:58px!important;  */
  /* background: #f1f1f1  */
  
}
body.landscape{
  xpadding-left:76px!important;
  
  /* padding-bottom:0px; */
  
}

.smothScroll{
  
  -webkit-overflow-scrolling: touch; 
  /* -webkit-transform: translate3d(0, 0, 0); */
}

.menuLandscape{
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  
  /* width: 160px;   */
  border-right: solid 0px var(--menuLandscapeborder);

  position: fixed; 
  top: 0px; 
  left: 0px;
  z-index:  100;
  height: 100%;
  xpadding-top: 14px;
  padding-top:20px;
  background:var(--color-bg2)!important;
  
  
}

.fixed{
  background: var(--color-bg1)!important;
  z-index: 3; 
  
  /* -webkit-transform: translateZ(0);
  transform: translateZ(0); */
  /* -webkit-backface-visibility: hidden; */

}
.render{-webkit-transform: translateZ(0);transform: translateZ(0)}
/* mac os 
body.landscape .fixed {padding-right: 68px!important;} */
 
 
 
.innerDiv{padding-left:5px;height:100%;}

.swipeDiv{ height: 100%;}

 /* body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px!important;
  height: 5px!important;
}  */
  
/* 

.modalBodyScroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 20px!important;
  height: 5px!important;
} */

.noMargin{
  margin:0px!important;
}

.fixedHeader{
  height: 100%;
}
.fixedHeader> .ui.secondary.menu {
  position: fixed;
  top:0px;
  width:100%;
  background: var(--color-bg1);
  z-index: 1;
  padding-top: 12px;
}

 


 

#root{
  height: 100%;  
}
body { 
 
  /* margin: 0;
  padding: 0; */
  font-size: 16px;
  height: 100%;  
  -webkit-print-color-adjust: exact !important;

}


.closeButton{
  padding: 7px!important;
  margin-top: -7px!important;
  margin-right: -9px!important;
}



.foo{
  width: calc(100% - 10px);
}

 
.scheduelHeaderCell{ 
  font-weight: normal!important;
  color:var(--color-text-header)!important;
  text-align: center!important;
  background-color: var(--color-bg1)!important;
  vertical-align: bottom!important;
  padding:0px!important;
  padding-bottom:2px!important;
  padding-top:2px!important;
  border-left:solid 1px var(--color-bg1)!important;
  border-right:solid 1px var(--color-bg1);
  
  
}

.tableBody thead tr , 
.tableBody tbody tr {
    display: table;
    width: 100%;  
    table-layout: fixed;
    empty-cells: show;
    
 }
  

  .tableBodyScroll thead tr,
  .tableBodyScroll tbody tr {
    display: table;
    width: calc(100% - 7px);  
    table-layout: fixed;
    /* background-color:#FCFCFC; */
  }

  .shift{
    text-align:center;
  }
 
  
  .cellMulti{
    flex-grow:1;
    padding:8px!important; 
    margin:2px!important;
    text-overflow: ellipsis;
    overflow: hidden;
    
    /* width:calc(100% -2px) */
  }
  .ui.circular.button {
    border-radius: 10em!important ;
    
  }
  .cellMulti > div{
    color:var(--cell-color-text);
    font-size: 13px!important;
  }
  .cellMulti > span{
    font-size: 12px!important;
    font-weight: 400;
    white-space: nowrap;
  }



 
.topMenuWeekDateMainDivTools{
  padding:10px;
  cursor: pointer;
  margin: 0px
}
.topMenuWeekDateMainDivTools >*{
  cursor: pointer!important;

}

.topMenuWeekDateDiv{
  cursor: pointer;
  color: gray

}

.topMenuWeekDateMainDiv{
  cursor: pointer; 
  min-width: 160px;
  text-align: center; 
  color: gray
}
.topMenuWeekDateMainWeek{
  font-size: 30px;
font-weight: normal;
}
.topMenuWeekDateMainB{
  font-size: 35px;
  xcolor:deepskyblue;
  xfont-weight: 900;
  
}
.loginBorderRight{
  border-right: dotted 1px #585858;
  padding-right:20px; 
  margin: 0px;
}


 
@media only screen and (max-width:700px) {
  .hideOnSmallScreen {
    display:none;
  }

}

/* .tableBodyScroll tr:hover {background-color: rgba(255,255,255,0.8)} */

/* @media only screen and (max-width:930px) {
 
  .topMenuWeekDateMainSpanTools{
    display: none;
  }
  .topMenuWeekDateDiv{
    font-size:9px ;
  }
  .topMenuWeekDateMainWeek{
    display: none;
  }
  .topMenuWeekDateMainDiv{
    min-width: 60px;
    width: 70px;
    margin:0px!important;
    cursor:pointer;
  } 

  .topMenuWeekDateMainB{
    font-size: 19px;
    color:deepskyblue;
    xfont-weight: 900;
  }
} */

  
 
.userCard{
  max-width: 900%;
  min-width: 200px;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
   overflow: hidden;
  padding: 15px;
  xmargin: 10px;
  border-right: dotted 1px var(--color-border);
  border-bottom: dotted 1px var(--color-border);

  xborder-radius:10px;  
  xborder:solid 4px rgba(38, 12, 12, 0.04);
}
 

@media (orientation: portrait) {
  
   

  .tdDateHeader{
    font-size:13px;
    
    
  }

 

  .btnMobile{
    font-size: 22px!important
  }

   
  

}


@media (orientation: landscape) {
 
  .tdDateHeader{
     
    
  }
}
 
.tdDateHeader{
      color: #c2c0c2;
      text-align: center!important;
      
    
}
 


.modalBody{
  height:100%; 
  border:solid 1px green;
  padding:0px;
}
.modalBodyScrollElectron{
  /* height: calc(100vh - 76px);   */
  xheight: 100%;  
  margin:0px; 
  overflow-y: auto; 
  overflow-x: hidden;
 }

.modalBodyScroll{
  margin:0px;  
  height: calc(100vh - 100px);  
  
  overflow-y: auto; 
  overflow-x: hidden;
  /* -webkit-overflow-scrolling: touch; 
  -webkit-transform: translate3d(0, 0, 0); 
  transform: translate3d(0, 0, 0);  */
  }
  .modalBodyScrollNoAction{
    margin:0px;  
    height: calc(100vh);  
    
    overflow-y: auto; 
    overflow-x: hidden;
    
    /* -webkit-overflow-scrolling: touch; 
    -webkit-transform: translate3d(0, 0, 0); 
    transform: translate3d(0, 0, 0);  */
    }
 /* USE TO NO SELECT , not on inputs */
 /* div, div::after, div::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
}  */
/* span, span::after, span::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
}
td, td::after, td::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
}
a, a::after, a::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
}
img, img::after, img::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
} */

 

/* *, *::after, *::before {
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
} */

.noSelect{
  -webkit-user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
	cursor: default;
}

.dragMe{
  -webkit-user-drag:element;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  
}
.custom-file-input::before {
  content: 'Upload';
  width:138px!important;
  height: 34px!important;
  display: inline-block!important;
  background-color: #babbbc;
  text-shadow: none;
  background-image: none;
  border-radius: 10px;
  padding: 10px 45px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: bold;
  
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: var(--color-bg1);
  
}


/* *** ENABLE FOR WINDOWS AND WEB*** */
/* body::-webkit-scrollbar-button{ display: block; height: 0px; border-radius: 0px; background-color: #AAA; } 
body::-webkit-scrollbar-button:hover{ background-color: #AAA; } 
body::-webkit-scrollbar-thumb{ background-color: #CCC; } 
body::-webkit-scrollbar-thumb:hover{ background-color: #CCC; } 
body::-webkit-scrollbar-track{ background-color: #efefef;margin-bottom:56px;margin-top:52px!important } 
body.landscape::-webkit-scrollbar-track{ background-color:#efefef!important;margin-top:52px!important; margin-bottom:0px!important;} 
body::-webkit-scrollbar-track:hover{ background-color: var(--color-bg1); } 
body::-webkit-scrollbar{ width: 8px; } */

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  }

textarea {
    margin: 0;
    -webkit-appearance: none;
    tap-highlight-color: hsla(0,0%,100%,0);
    padding: .78571429em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    outline: none;
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    box-shadow: inset 0 0 0 0 transparent;
    -webkit-transition: color .1s ease,border-color .1s ease;
    transition: color .1s ease,border-color .1s ease;
    font-size: 1em;
    line-height: 1.2857;
    resize: vertical;
    resize: none;
}